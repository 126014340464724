<template>
  <section id="custom-select">
    <div
      v-click-outside="hide"
      class="root-cont"
      style="width: 205px"
      :style="disabled ? 'cursor: not-allowed; background:#F2F3F6' : ''"
    >
      <span
        class="placeholder-span"
        @click.stop="show"
        ><span v-if="!multiple"
          ><span
            v-if="!show_list || !searchable"
            class="single-placeholder"
            >{{ value?.name ?? placeholder }}
          </span>
          <input
            v-if="show_list && searchable"
            ref="search-select-input"
            v-model="search"
            style="border: none !important; height: 28px !important; width: 170px"
        /></span>
        <span
          v-if="multiple"
          style="overflow: hidden"
        >
          <div
            v-if="!show_list"
            class="multi-placeholder"
          >
            <span
              v-if="value?.length > 1"
              class="count-tag"
              >{{ value?.length }}</span
            >
            <span class="select-placeholder ml-2">{{ multi_tag }}</span>
          </div>
          <input
            v-if="show_list && searchable"
            ref="search-select-input"
            v-model="search"
            style="border: none !important; height: 28px !important"
          />
        </span>
        <img
          src="/img/icons/arrow_up.svg"
          style="transform: rotate(180deg)"
        />
      </span>
      <transition duration="200">
        <div
          v-if="!group_select && show_list"
          class="option-container"
        >
          <div
            v-for="item of filtered_list"
            :key="item.id"
            class="option"
            @click.stop="select_item(item)"
          >
            <img
              v-if="multiple"
              :src="checkbox(item)"
              class="m-0 mr-2"
            />
            <span style="overflow: hidden; text-overflow: ellipsis">
              <slot
                name="option_container"
                :item="item"
                :value="item[name_field]"
                >{{ item[name_field] }}</slot
              >
            </span>
          </div>
          <div
            v-if="search?.length && !this.filtered_list?.length"
            class="center p-2"
          >
            Ничего не найдено!
          </div>
          <slot name="list-footer"></slot>
        </div>
      </transition>
      <div
        v-if="group_select"
        class="option-container"
      >
        <div class="option">123</div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'CustomSelect',
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      group_select: {
        type: Boolean,
        default: false
      },
      reducer: {
        type: Function,
        default: (val) => val
      },
      compare_fn: {
        type: Function,
        default: (val, el) => val.id === el.id
      },
      searchable: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: '',
        required: true
      },
      multi_placeholder: {
        type: String,
        default: null
      },
      value: {
        default: null
      },
      name_field: {
        type: String,
        default: 'name'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        show_list: false,
        search: ''
      }
    },
    computed: {
      multi_tag() {
        return this.value.length
          ? this.value.length > 1
            ? this.multi_placeholder
            : this.items?.find((el) => this.compare_fn(el, this.value[0]))?.[this.name_field]
          : this.placeholder
      },
      filtered_list() {
        if (!this.searchable) return this.items
        return this.items?.filter((el) => el[this.name_field]?.toLowerCase()?.includes(this.search))
      }
    },
    methods: {
      select_item(item) {
        let value = this.value || []
        if (this.multiple) {
          if (this.value?.some((el) => this.compare_fn(item, el)))
            value = this.value?.filter((el) => !this.compare_fn(item, el))
          else value.push(this.reducer(item))
        } else {
          this.show_list = false
          value = item
        }
        this.$emit('input', value)
      },
      show() {
        if (this.disabled) return
        this.show_list = !this.show_list
        if (this.searchable) this.$nextTick(() => this.$refs['search-select-input'].select())
      },
      hide() {
        this.show_list = false
        this.search = ''
      },
      checkbox(item) {
        return `/img/icons/checkbox_${this.value?.some((el) => this.compare_fn(item, el))}.svg`
      }
    }
  }
</script>

<style lang="scss" scoped>
  #custom-select {
    user-select: none;

    .root-cont {
      position: relative;
      display: flex;
      flex-direction: row;
      background: white;
      border: 1px solid #bcbcbc;
      border-radius: 2px;
      height: 32px;
      font-size: 14px;
      align-items: center;
      padding-right: 10px;
      cursor: pointer;
      justify-content: space-between;
    }

    .multi-placeholder {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 4px;
      height: 32px;
    }

    .count-tag {
      display: flex;
      align-items: center;
      color: white;
      font-size: 14px;
      background: #00a3ff;
      border-radius: 5px;
      padding: 2px 6px;
      height: 28px;
    }

    .option-container {
      width: 205px;
      position: absolute;
      z-index: 10000;
      max-height: 360px;
      top: 35px;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      transition: 200ms;
      overflow-y: auto;
    }

    .option {
      padding: 4px 23px 4px 12px;
      transition: 200ms;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      user-select: none;
      &:hover {
        background: #f2f3f7;
      }
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .placeholder-span {
      overflow: hidden;
      display: flex;
      flex: 1;
      padding: 4px;
      flex-direction: row;
      justify-content: space-between;
    }

    .select-placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      white-space: nowrap;
    }

    .single-placeholder {
      padding: 4px;
      color: black;
      width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
</style>
