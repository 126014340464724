<template>
  <section id="auto-order">
    <!-- <div style="display: flex; flex-direction: row; align-items: baseline">
      <breadcrumbs />
      <span class="mr-2">|</span>
      <span style="color: black"> на {{ convertDate(order.date) }}</span>
    </div> -->
    <product-movement-modal :product="selectedProduct" />
    <categories-load-modal
      :included_categories="order.selectedCategories"
      :enabled_categories="order.filters.categories"
      @add-categories="addCategories"
    />
    <div class="autoorder-head">
      <div
        class="auto-filters"
        style="flex: 1"
      >
        <!--        <date-picker-->
        <!--          style="flex: 0.7"-->
        <!--          format="dd.MM.yyyy"-->
        <!--          placeholder="Выберите дату"-->
        <!--          v-model="order.supply_date"-->
        <!--          :disabled="order.fixed"-->
        <!--          @on-change="updateHead"-->
        <!--          :clearable="false"-->
        <!--          :options="dateOptions"-->
        <!--        />-->
        <div
          class="filter-item"
          @click.capture="showLoadModal"
        >
          <div
            class="categories-select"
            style="width: 205px"
          >
            <span style="color: black; white-space: nowrap">{{ categoriesCount }}</span>
            <img
              src="/img/icons/arrow_up.svg"
              style="transform: rotate(180deg)"
            />
          </div>
        </div>
        <custom-select
          placeholder="Все поставщики"
          multiple
          searchable
          multi_placeholder="Несколько поставщиков"
          :reducer="(el) => el.id"
          :items="filtred_suppliers"
          :compare_fn="(val, el) => val.id === el"
          :value="order.selectedSuppliers"
          @input="setSuppliers"
        />
        <div style="width: 200px">
          <e-select
            v-model="active_entity"
            :options="entity_list"
            multiple
            serchable
            :max_selected_count="1"
            placeholder="Все организации"
            select_name="entity_select"
          >
            <template #custom_name="{ item }">
              <div class="entity">
                <p class="name">{{ item.name }}</p>
                <div class="inn_kpp">
                  <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
                  <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
                </div>
              </div>
            </template>
          </e-select>
        </div>
        <b-form-checkbox
          :checked="all"
          @change="changeAll"
        >
          по заказу
        </b-form-checkbox>
        <span
          v-if="order.selectedSuppliers?.length || order.selectedCategories?.length"
          style="text-decoration: underline; cursor: pointer"
          @click="resetFilters"
          >Сбросить фильтры</span
        >
      </div>
      <div class="auto-filters">
        <b-button
          variant="outline-primary"
          @click="printOrder"
        >
          Печать
        </b-button>
        <b-button
          variant="primary"
          @click="generateOrder"
        >
          Заказать
        </b-button>
        <b-dropdown
          no-caret
          variant="none"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/more.svg"
                alt=""
              />
            </div>
          </template>
          <!--          <b-dropdown-item @click="clearOrder">-->
          <!--            Обновить заказ-->
          <!--          </b-dropdown-item>-->
          <b-dropdown-item
            :disabled="loading"
            @click="recalculate"
          >
            Пересчитать заказ
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div
      v-show="loading"
      class="card"
      style="height: 100%; justify-content: center"
    >
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="Загрузка..."
        />
        <div class="mt-2"><strong>Пересчитываем заказ...</strong></div>
      </div>
    </div>
    <resizable-table
      v-show="!loading"
      style="margin: 0 !important"
      table_name="auto-order"
      :default_fields="fields"
      :items="order.specifications.specification"
      :busy="firstLoading || loading"
      show_product_search
      @scroll-up="scrollUp"
      @scroll-down="scrollDown"
      @sort_change="sort_change"
    >
      <template #table_header>
        <div class="p-3">
          <div style="display: flex; flex-direction: row">
            <b-form-input
              v-model="filter"
              type="text"
              class="filter-search w-25"
              placeholder="Поиск"
              :is-keyup="true"
              @input="resetPagination"
            />
            <b-dropdown
              no-caret
              variant="light"
              class="ml-3"
              style="height: 32px; width: 32px"
            >
              <template #button-content>
                <div class="btn-more">
                  <img
                    src="/img/icons/settings.svg"
                    alt=""
                  />
                </div>
              </template>
              <b-dropdown-item v-b-modal.sort-table-modal> Изменить столбцы </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>

      <template #total>
        <div class="ml-auto d-flex">
          <div class="footer-data">Итого: {{ order.specifications.total }}</div>
          <div class="footer-data">Сумма, ₽: {{ formatPrice(order.specifications.order_sum) }}</div>
        </div>
      </template>

      <template #productSearch>
        <div
          class="d-flex"
          style="width: 250px"
        >
          <product-search
            v-if="!order.fixed"
            :supplier="order?.supplier?.id ?? null"
            document_type="order"
            :document_head="order.id"
            :document="order"
            @select_product="select_product"
          />
        </div>
      </template>

      <template #head_id>
        <b-checkbox
          v-model="isAllSelected"
          @change="setSelected"
        />
      </template>

      <template #body_id="{ item }">
        <b-checkbox
          :checked="selected.some((el) => el.product.id === item.product.id)"
          @click.stop
          @change="(val) => addProduct(val, item)"
        />
      </template>

      <template #body_order="{ item }">
        <span class="d-flex float-right">
          {{ item.int_order }}
        </span>
      </template>

      <template #body_product="{ item }">
        <div style="display: flex; flex-direction: column; text-align: left">
          <span style="text-overflow: ellipsis; overflow: hidden">{{ item?.product?.name }}</span>
          <div class="product-info-div">
            <span class="mr-2">{{ item?.product?.articul }}</span
            ><span class="supplier">
              {{ item.product?.supplier?.name ?? '-' }}
            </span>
          </div>
        </div>
      </template>

      <template #body_measurement="{ item }">
        <span class="d-flex float-left">
          {{ item.product?.measurement?.name ?? '' }}
        </span>
      </template>

      <template #body_delta="{ item }">
        <span class="d-flex float-right">
          <table-row-input
            :ref="'delta' + item.id"
            :fixed="order.fixed"
            :value="item.delta"
            @handle-input="(val) => setDelta(val, item)"
            @input="(val) => setDelta(val, item)"
          />
        </span>
      </template>

      <template #body_step_delta="{ item }">
        <span class="d-flex float-right">
          <table-row-input
            :ref="'step_delta' + item.id"
            :fixed="order.fixed"
            def_val="-"
            :value="item.step_delta"
            @handle-input="(val) => setStepDelta(val, item)"
            @input="(val) => setStepDelta(val, item)"
          />
        </span>
      </template>

      <template #body_price="{ item }">
        <span class="d-flex float-right">
          <table-row-input
            :ref="'price' + item.id"
            :fixed="order.fixed"
            def_val="-"
            :value="item.price"
            @handle-input="(val) => setPrice(val, item)"
            @input="(val) => setPrice(val, item)"
          />
        </span>
      </template>

      <template #body_sum="{ item }">
        <span class="d-flex float-right">
          {{ item.sum ? formatPrice(item.sum) : '-' }}
        </span>
      </template>

      <template #body_supply_date="{ value }">
        <span class="d-flex float-left">
          {{ value ? convertDate(value) : '-' }}
        </span>
      </template>

      <template #body_remainder="{ item }">
        <span class="d-flex float-right">
          {{ formatDelta(getTotalRemainders(item)) }}
        </span>
      </template>

      <template #body_sold="{ item }">
        <span class="d-flex float-right">
          {{ formatDelta(item.sold) }}
        </span>
      </template>

      <template #body_sold_rate="{ item }">
        <span class="d-flex float-right">
          {{ formatPrice(item.sold_rate) }}
        </span>
      </template>

      <template #body_sold_period="{ item }">
        <span class="d-flex float-right">
          {{ formatPrice(item.sold_period) }}
        </span>
      </template>

      <template #body_critical_level="{ item }">
        <span class="d-flex float-right">
          <table-row-input
            :ref="'critical_level' + item.id"
            :fixed="order.fixed"
            :value="item.critical_level"
            @handle-input="(val) => setCriticalLevel(val, item)"
            @input="(val) => setCriticalLevel(val, item)"
          />
        </span>
      </template>
    </resizable-table>
    <order-navbar
      :items="selected"
      :all_selected="isAllSelected"
      :clear_selected="clearSelected"
      :count="order.specifications.total"
      @remove_items="removeItems"
      @show_move="showMovement"
    />
  </section>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable'
  import { mapActions, mapGetters } from 'vuex'
  import formatDate from '@/utils/formatDate'
  import { OrderHeadModel } from '@/views/order/model/order-head.model'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal'
  import { ProductModel } from '@/models/product.model'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal'
  import { http } from '@/http/http'
  import OrderNavbar from '@/views/order/components/OrderNavbar'
  import CustomSelect from '@/components/CustomSelect'
  import { print_html } from '@/utils/print'

  export default {
    name: 'AutoOrder',
    components: {
      CustomSelect,
      OrderNavbar,
      ProductMovementModal,
      ProductSearch,
      TableRowInput,
      ResizableTable,
      CategoriesLoadModal
    },
    apollo: {
      AutoOrder: {
        query: require('../gql/autoOrder.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch.id
            },
            pagination: { ...this.pagination, search: '' },
            all: !this.all
          }
        },
        result({ data }) {
          this.selected = []
          if (data) this.getHead(data)
        }
      },
      RecalculateSubscription: {
        fetchPolicy: 'no-cache',
        query: require('../gql/RecalculateSubscription.graphql'),
        variables() {
          return {
            input: {
              branch: this.currentBranch.id,
              id: this.order.id
            }
          }
        },
        skip() {
          return !this.order.id
        },
        result({ data }) {
          if (data?.RecalculateSubscription?.status === 'started') this.loading = true
          else if (data?.RecalculateSubscription?.status === 'finished') {
            this.loading = false
            this.order.specifications.specification = []
            this.firstLoading = true
            this.$apollo.queries.AutoOrder.refresh()
          } else if (data?.RecalculateSubscription?.status === 'error') {
            this.loading = false
            this.$noty.error('Ошибка пересчета заказа')
          }
        }
      },
      Suppliers: {
        query: require('../../operational-processes/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data?.Suppliers ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    data() {
      return {
        active_entity: [],
        entity_list: [],
        all: true,
        filter: '',
        dateOptions: {
          disabledDate(date) {
            return date.valueOf() < Date.now() - 86400000
          }
        },
        loading: false,
        selectedProduct: new ProductModel(),
        selectedCategories: [],
        supplier_list: [],
        selectedSupplier: [],
        isAllSelected: false,
        selected: [],
        firstLoading: true,
        debounce: null,
        pagination: {
          skip: 0,
          take: 15,
          order: [
            {
              key: 'delta',
              value: 'DESC'
            }
          ]
        },
        order: new OrderHeadModel(),
        fields: [
          { key: 'id', label: '', checked: true, width: 45 },
          {
            key: 'order',
            label: '№',
            width: 70,
            checked: true,
            sortable: true
          },
          {
            key: 'product',
            label: 'Наименование товара',
            width: 220,
            checked: true,
            sortable: true
          },
          {
            key: 'measurement',
            label: 'Ед. изм.',
            width: 80,
            checked: true
          },
          {
            key: 'delta',
            label: 'Кол-во',
            width: 120,
            checked: true,
            sortable: true
          },
          {
            key: 'price',
            label: 'Цена, ₽',
            width: 120,
            checked: true,
            sortable: true
          },
          {
            key: 'sum',
            label: 'Сумма, ₽',
            width: 120,
            checked: true,
            sortable: true
          },
          {
            key: 'remainder',
            label: 'Остаток',
            width: 100,
            checked: true,
            sortable: false
          },
          {
            key: 'sold',
            label: 'Реализовано',
            width: 150,
            checked: true,
            sortable: true,
            hint: 'Количество проданного товара \n' + 'от прошлой поставки'
          },
          {
            key: 'sold_rate',
            label: 'Скорость',
            width: 120,
            checked: true,
            sortable: true,
            hint: 'Среднее количество \n проданного товара в день'
          },
          {
            key: 'supply_date',
            label: 'Дата поставки',
            width: 150,
            checked: true,
            sortable: true
          },
          {
            key: 'step_delta',
            label: 'Кратность',
            width: 135,
            checked: true,
            sortable: true,
            hint: 'Количество товара в упаковке'
          },
          {
            key: 'critical_level',
            label: 'Крит. уровень',
            width: 145,
            checked: true,
            sortable: true,
            hint: 'Минимально необходимый остаток товара на складе'
          },
          {
            key: 'sold_period',
            label: 'Период обор., д.',
            width: 300,
            checked: true,
            sortable: true,
            hint: 'Расчетный период за который \n' + 'реализован товар'
          }
        ]
      }
    },
    mounted() {
      this.entity_list = this.currentBranch?.entities
      if (this.filter_params?.entity?.id) {
        this.active_entity = [this.filter_params?.entity]
      }
    },
    beforeDestroy() {
      this.setBreadrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadrumbs: 'breadcrumbs/set_current'
      }),
      addProduct(val, prod) {
        if (val) this.selected = [...this.selected, prod]
        else {
          this.selected = this.selected?.filter((el) => el.product.id !== prod.product.id)
        }
      },
      async fetchHead(pagination) {
        const { data } = await this.$apollo.query({
          query: require('../gql/autoOrder.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              branch: this.currentBranch.id
            },
            pagination: { ...pagination, search: this.filter },
            all: !this.all
          }
        })
        this.getHead(data)
      },
      getHead(data) {
        const oldspec = !this.firstLoading
          ? this.order.specifications.specification?.map((el) => {
              el.price = el.price || el.product?.purchasePrice || 0
              return el
            }) || []
          : []
        this.order = new OrderHeadModel(data.AutoOrder)
        this.setBreadrumbs({ date: data.AutoOrder.date })
        if (this.order.recalculation_status === 'started') this.loading = true
        if (!this.firstLoading) {
          // const newDoc = new PaymentHeadModel(data.IncomingHead)
          if (!this.forwardDirection) {
            this.order.specifications.specification?.unshift(...oldspec)
          } else {
            const initialHeight = document.querySelector('.table-docs').scrollHeight
            this.order.specifications.specification?.push(...oldspec)
            if (oldspec.length > 0)
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 10
              })
          }
        }
        if (this.isAllSelected) this.selected = this.order.specifications.specification

        this.firstLoading = false
      },
      convertDate(date) {
        if (date && date instanceof Date && !isNaN(date)) return formatDate(new Date(date), 'numeric')
        else return formatDate(new Date(), 'numeric')
      },
      getTotalRemainders(item) {
        let rems = 0
        item.product?.remainders?.forEach((el) => (rems += el.value))
        return rems
      },
      formatPrice(price) {
        return new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        }).format(price)
      },
      formatDelta(delta) {
        return new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        }).format(delta)
      },
      async getByOrder(spec, cb) {
        const pagination = this.pagination
        pagination.skip = spec.int_order - 2
        if (pagination.skip > this.order.specifications.total - pagination.take)
          pagination.skip = this.order.specifications.total - pagination.take
        this.first = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      changeAll(value) {
        this.pagination.skip = 0
        this.firstLoading = true
        this.all = value
      },
      async select_product(product) {
        if (product.specification) {
          if (!this.$refs['delta' + product.specification.id]) {
            this.getByOrder(product.specification, () => this.scroll_to(product))
          } else {
            this.scroll_to(product)
          }

          return
        }

        this.createSpec(product)
      },
      async createSpec(product) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/createOrderSpec.graphql'),
          variables: {
            input: {
              product: product.id,
              document_head: this.order.id
            }
          }
        })

        await this.getLast(() => {
          setTimeout(() => {
            document.querySelector('.table-docs').scrollTo({
              top: document.querySelector('.table-docs').scrollHeight,
              left: 0
            })
          }, 100)
          this.$nextTick(() => {
            this.$refs['delta' + data.CreateOrderSpec.id].showInput()
          })
        })

        this.$noty.show('Добавлено')

        document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
      },
      async getLast(cb) {
        this.forwardDirection = true
        const pagination = Object.assign(this.pagination)
        pagination.skip = this.order.specifications.total - pagination.take + 1
        if (pagination.skip < 0) pagination.skip = 0
        this.firstLoading = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      async scrollDown() {
        const lastItem = this.order.specifications.specification?.[this.order.specifications.specification?.length - 1]
        if (!lastItem) return
        const pagination = JSON.parse(JSON.stringify(this.pagination))
        this.forwardDirection = false
        pagination.skip = lastItem.int_order
        if (pagination.skip >= this.order.specifications.total) {
          return
        }
        pagination.take = this.pagination.take

        this.pagination = pagination
        this.pagination.take = 10
      },
      async scrollUp() {
        const firstElement = this.order.specifications.specification?.[0]
        if (!firstElement || firstElement?.int_order <= 1) return
        const pagination = Object.assign(this.pagination)
        this.forwardDirection = true
        pagination.skip = firstElement.int_order - pagination.take - 2
        if (pagination.skip < 0) {
          pagination.take = pagination.take + pagination.skip
          pagination.skip = 0
        }
        this.fetchHead(pagination)
      },
      setSelected(val) {
        if (val) this.selected = this.order.specifications.specification
        else this.selected = []
      },
      setDelta(val, item) {
        item.setDelta(val)
        this.updateSpec(item)
      },
      setStepDelta(val, item) {
        item.setStepDelta(val)
        this.updateSpec(item)
      },
      setPrice(val, item) {
        item.setPrice(val)
        this.updateSpec(item)
      },
      setCriticalLevel(val, item) {
        item.setCriticalLevel(val)
        this.updateSpec(item)
      },
      async removeItems() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/removeOrderSpec.graphql'),
          variables: {
            input: {
              document_head: this.order.id,
              ids: this.selected.map((el) => el.id),
              isAllSelected: this.isAllSelected
            }
          }
        })

        if (data?.RemoveOrderSpec?.status) {
          this.order.specifications.specification = []
          this.pagination.skip = 0
          this.$apollo.queries.AutoOrder.refresh()
          this.selected = []
        }
      },
      clearSelected() {
        this.selected = []
      },
      resetPagination() {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.firstLoading = true
          this.order.specifications.specification = []
          this.fetchHead({
            skip: 0,
            take: 15
          })
        }, 500)
      },
      async updateSpec(spec) {
        clearTimeout(this.debounce)

        this.debounce = setTimeout(
          async () =>
            await this.$apollo.mutate({
              mutation: require('../gql/createOrderSpec.graphql'),
              variables: {
                input: {
                  ...spec.input,
                  document_head: this.order.id
                }
              }
            }),
          500
        )
      },
      showLoadModal() {
        if (this.order.fixed) return
        this.$bvModal.show('categories-modal')
      },
      async addCategories(cats) {
        this.order.selectedCategories = cats
        await this.updateHead()
        this.order.specifications.specification = []
        this.pagination = {
          skip: 0,
          take: 15
        }
        await this.$apollo.queries.AutoOrder.refresh()
      },
      async setSuppliers(value) {
        this.order.selectedSuppliers = value
        this.order.selectedEntities = this.active_entity.map((obj) => obj.id)
        await this.updateHead()
        this.order.specifications.specification = []
        this.pagination = {
          skip: 0,
          take: 15
        }
        await this.$apollo.queries.AutoOrder.refresh()
      },
      async resetFilters() {
        this.order.selectedSuppliers = []
        this.order.selectedCategories = []
        await this.updateHead()
        this.order.specifications.specification = []
        this.pagination = {
          skip: 0,
          take: 15
        }
        await this.$apollo.queries.AutoOrder.refresh()
      },
      showMovement(item) {
        this.selectedProduct = new ProductModel(
          this.order.specifications.specification.find((el) => el.id === item.id).product
        )
        this.$bvModal.show('product-movement')
      },
      async updateHead() {
        await this.$apollo.mutate({
          mutation: require('../gql/updateHead.graphql'),
          variables: {
            input: this.order.input
          }
        })
      },
      async recalculate() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/recalculateOrder.graphql'),
          variables: {
            input: {
              id: this.order.id,
              branch: this.currentBranch.id
            }
          }
        })

        if (data?.RecalculateOrder?.status) {
          this.order.specifications.specification = []
          this.order.specifications.total = 0
          this.pagination = {
            skip: 0,
            take: 15
          }
          this.firstLoading = true
          this.$apollo.queries.AutoOrder.refetch()
        }
      },
      async clearOrder() {
        this.order.selectedCategories = []
        this.order.selectedSuppliers = []
        this.order.supply_date = new Date()
        await this.updateHead()
        await this.recalculate()
      },
      async generateOrder() {
        this.firstLoading = true
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/ChangeOrderStatus.graphql'),
          variables: {
            input: {
              fixed: true,
              id: this.order.id
            }
          }
        })
        if (data?.FixOrder?.status) {
          this.$router.push({ name: 'orders' })
        }
        this.firstLoading = false
      },
      async printOrder() {
        try {
          const { data } = await http.get(`templates/order?order=${this.order.id}`)
          if (data) {
            print_html(data)
          } else {
            this.$noty.error('Ошибка печати')
          }
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка печати')
        }
      },
      sort_change(td) {
        this.pagination = {
          order: [{ key: td.key, value: td.sort }],
          skip: 0,
          take: 15
        }
        this.order.specifications.specification = []
        this.$apollo.queries.AutoOrder.refetch()
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      categoriesCount() {
        if (this.order.selectedCategories?.length) {
          return 'Выбрано ' + this.order.selectedCategories?.length
        }
        return 'Все группы товаров'
      },
      filtred_suppliers() {
        return this.all
          ? this.supplier_list?.filter((el) => this.order?.filters?.suppliers?.includes(el.id))
          : this.supplier_list
      }
    },
    watch: {
      async active_entity(newVal) {
        this.order.setSelectedEntities(newVal.map((obj) => obj.id))
        await this.updateHead()
        this.order.specifications.specification = []
        this.pagination = {
          skip: 0,
          take: 15
        }
        await this.$apollo.queries.AutoOrder.refresh()
      },
      selected(newVal, oldVal) {
        if (newVal?.length < oldVal?.length) this.isAllSelected = false
      },
      order() {
        if (this.order?.entity?.id) {
          this.active_entity = [this.order.entity]
        }
        setTimeout(() => {
          this.$nextTick(() => {
            const table = document.querySelector('.table-docs')
            if (table.scrollHeight === table.clientHeight) {
              this.scrollDown()
            }
          })
        }, 100)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .ivu-select-input::placeholder {
      color: black;
    }

    .table-docs thead th {
      border-top: none;
    }

    .b-action-buttons {
      button {
        min-width: 87px;
        justify-content: center;
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    table {
      tr {
        th {
          border-bottom: 1px solid #e1e1e1;
          border-top: none;
        }
        td {
          border-bottom: 1px solid #e1e1e1;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1;
        }
      }
    }

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 1px;
      }

      &__content {
        flex-grow: 1;
        min-height: 1px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    .table-docs {
      margin-bottom: -1px;
      height: 100%;
      overflow-x: scroll;

      thead {
        position: sticky;
        top: -1px;
        background: #fff;
        z-index: 9;

        th {
          border-bottom: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          border-top: none;
          div {
            justify-content: flex-start !important;
            display: flex;
            float: left;
          }
          .td-div {
            width: 100%;
          }
          .td-inner {
            width: 100%;
            display: flex;
            justify-content: space-between !important;
          }
        }
      }

      tr {
        th.b-checkbox {
          padding: 10px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }

      @media (max-height: 960px) {
        tr {
          td {
            &.td-name {
              padding: 5px 16px;

              .b-name.article {
                line-height: 17px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;
        }
      }
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;

        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .table-inner {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 1px;
      overflow-x: auto;

      .table-products {
        height: calc(100% - 58px);
        overflow-y: auto;
        overflow-x: hidden;
      }

      table {
        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }

          th {
            padding: 16px !important;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }

      .b-table__navbar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
      }
    }

    .b-search {
      &__wrapper {
        width: 100%;
        z-index: 10;

        & > div {
          position: fixed;

          & > div {
            position: fixed;
          }
        }
      }

      &-result {
        position: absolute;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 360px;
        overflow-y: auto;
        z-index: 10000;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;
          }
        }
      }
    }

    .b-table__navbar {
      margin: -85px 15px 0;
    }

    .count {
      position: relative;
      display: inline-block;
      padding: 6px;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }
    }

    .ivu-tag {
      background: #00a3ff;
      border-radius: 5px;
      border: none;
    }

    .ivu-tag-text {
      color: white;
      font-size: 14px;
      line-height: 14px;
    }
  }

  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    p {
      margin-bottom: 0;
    }
    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }
    .inn_kpp {
      display: flex;
      .inn {
        margin-right: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }
  #auto-order {
    .footer-data {
      color: #313131;
      margin-left: 32px;
    }

    .flex-card {
      flex-grow: 1;
      min-height: 1px;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 1px;

    .table-docs {
      thead {
        th {
          div {
            justify-content: flex-start !important;
            display: flex;
            float: left;
          }
        }
      }

      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          img {
            margin-right: 0;
            transition: all 0.2s;
            transform: rotate(180deg);
          }

          &.not-collapsed {
            img {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .table-filter {
      button {
        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .table-inner {
    }

    .b-search {
      &__wrapper {
        & > div {
          position: fixed;

          & > div {
            position: fixed;
          }
        }
      }

      &-result {
        .item {
          &-info {
            span {
              margin-right: 12px;
            }
          }

          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .count {
      .cursor {
        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .product-info-div {
      display: flex;
      flex-direction: row;
      width: 90%;
      max-width: 380px;

      & > span {
        color: #888888;
        font-weight: 500;
      }

      & .supplier {
        color: #888888;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 1rem;
      }
    }

    .categories-select {
      display: flex;
      flex-direction: row;
      background: white;
      border: 1px solid #bcbcbc;
      border-radius: 2px;
      height: 32px;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      justify-content: space-between;
    }

    .autoorder-head {
      display: flex;
      gap: 16px;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .auto-filters {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
      align-items: center;
    }
  }

  .filter-item {
    width: 205px !important;
    min-width: initial !important;
    max-width: initial !important;
    margin: 0 !important;
  }
</style>
