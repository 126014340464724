<template>
  <div
    v-if="items.length > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear"
    />
    {{ all_selected ? count : items.length }}
    {{ item_name }}
    <div
      class="b-btns"
      style="margin-top: 0 !important"
    >
      <b-button
        variant="dark"
        @click="removeItem"
      >
        Убрать из заказа
      </b-button>
      <template v-if="items.length === 1">
        <b-button
          variant="dark"
          @click="showMovement"
        >
          Показать товародвижение
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      all_selected: {
        type: Boolean,
        default: false
      },
      count: {
        type: Number,
        default: 0
      },
      clear_selected: Function
    },

    computed: {
      item_name() {
        if (this.items.length === 1) return 'Товар'
        if (this.items.length > 1 && this.items.length < 5) return 'Товара'
        return 'Товаров'
      }
    },

    beforeDestroy() {
      this.clear()
    },

    methods: {
      removeItem() {
        this.$emit('remove_items', this.items)
      },
      showMovement() {
        this.$emit('show_move', this.items?.length ? this.items[0] : this.product_items[0])
      },
      clear() {
        if (this.clear_selected) this.clear_selected()
      }
    }
  }
</script>
