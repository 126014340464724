var render = function render(){
  var _vm$order$selectedSup, _vm$order$selectedCat;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "auto-order"
    }
  }, [_c('product-movement-modal', {
    attrs: {
      "product": _vm.selectedProduct
    }
  }), _c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.order.selectedCategories,
      "enabled_categories": _vm.order.filters.categories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('div', {
    staticClass: "autoorder-head"
  }, [_c('div', {
    staticClass: "auto-filters",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('div', {
    staticClass: "filter-item",
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "categories-select",
    staticStyle: {
      "width": "205px"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "white-space": "nowrap"
    }
  }, [_vm._v(_vm._s(_vm.categoriesCount))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })])]), _c('custom-select', {
    attrs: {
      "placeholder": "Все поставщики",
      "multiple": "",
      "searchable": "",
      "multi_placeholder": "Несколько поставщиков",
      "reducer": function (el) {
        return el.id;
      },
      "items": _vm.filtred_suppliers,
      "compare_fn": function (val, el) {
        return val.id === el;
      },
      "value": _vm.order.selectedSuppliers
    },
    on: {
      "input": _vm.setSuppliers
    }
  }), _c('div', {
    staticStyle: {
      "width": "200px"
    }
  }, [_c('e-select', {
    attrs: {
      "options": _vm.entity_list,
      "multiple": "",
      "serchable": "",
      "max_selected_count": 1,
      "placeholder": "Все организации",
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "entity"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "inn_kpp"
        }, [_c('p', {
          staticClass: "inn"
        }, [_vm._v("ИНН: " + _vm._s(item.inn ? item.inn : '-'))]), _c('p', {
          staticClass: "kpp"
        }, [_vm._v("КПП: " + _vm._s(item.kpp ? item.kpp : '-'))])])])];
      }
    }]),
    model: {
      value: _vm.active_entity,
      callback: function ($$v) {
        _vm.active_entity = $$v;
      },
      expression: "active_entity"
    }
  })], 1), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.all
    },
    on: {
      "change": _vm.changeAll
    }
  }, [_vm._v(" по заказу ")]), (_vm$order$selectedSup = _vm.order.selectedSuppliers) !== null && _vm$order$selectedSup !== void 0 && _vm$order$selectedSup.length || (_vm$order$selectedCat = _vm.order.selectedCategories) !== null && _vm$order$selectedCat !== void 0 && _vm$order$selectedCat.length ? _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.resetFilters
    }
  }, [_vm._v("Сбросить фильтры")]) : _vm._e()], 1), _c('div', {
    staticClass: "auto-filters"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.printOrder
    }
  }, [_vm._v(" Печать ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.generateOrder
    }
  }, [_vm._v(" Заказать ")]), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/more.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.recalculate
    }
  }, [_vm._v(" Пересчитать заказ ")])], 1)], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "card",
    staticStyle: {
      "height": "100%",
      "justify-content": "center"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  }), _vm._m(0)], 1)]), _c('resizable-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }],
    staticStyle: {
      "margin": "0 !important"
    },
    attrs: {
      "table_name": "auto-order",
      "default_fields": _vm.fields,
      "items": _vm.order.specifications.specification,
      "busy": _vm.firstLoading || _vm.loading,
      "show_product_search": ""
    },
    on: {
      "scroll-up": _vm.scrollUp,
      "scroll-down": _vm.scrollDown,
      "sort_change": _vm.sort_change
    },
    scopedSlots: _vm._u([{
      key: "table_header",
      fn: function () {
        return [_c('div', {
          staticClass: "p-3"
        }, [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row"
          }
        }, [_c('b-form-input', {
          staticClass: "filter-search w-25",
          attrs: {
            "type": "text",
            "placeholder": "Поиск",
            "is-keyup": true
          },
          on: {
            "input": _vm.resetPagination
          },
          model: {
            value: _vm.filter,
            callback: function ($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }), _c('b-dropdown', {
          staticClass: "ml-3",
          staticStyle: {
            "height": "32px",
            "width": "32px"
          },
          attrs: {
            "no-caret": "",
            "variant": "light"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "btn-more"
              }, [_c('img', {
                attrs: {
                  "src": "/img/icons/settings.svg",
                  "alt": ""
                }
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.sort-table-modal",
            modifiers: {
              "sort-table-modal": true
            }
          }]
        }, [_vm._v(" Изменить столбцы ")])], 1)], 1)])];
      },
      proxy: true
    }, {
      key: "total",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto d-flex"
        }, [_c('div', {
          staticClass: "footer-data"
        }, [_vm._v("Итого: " + _vm._s(_vm.order.specifications.total))]), _c('div', {
          staticClass: "footer-data"
        }, [_vm._v("Сумма, ₽: " + _vm._s(_vm.formatPrice(_vm.order.specifications.order_sum)))])])];
      },
      proxy: true
    }, {
      key: "productSearch",
      fn: function () {
        var _vm$order$supplier$id, _vm$order, _vm$order$supplier;

        return [_c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "width": "250px"
          }
        }, [!_vm.order.fixed ? _c('product-search', {
          attrs: {
            "supplier": (_vm$order$supplier$id = (_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : (_vm$order$supplier = _vm$order.supplier) === null || _vm$order$supplier === void 0 ? void 0 : _vm$order$supplier.id) !== null && _vm$order$supplier$id !== void 0 ? _vm$order$supplier$id : null,
            "document_type": "order",
            "document_head": _vm.order.id,
            "document": _vm.order
          },
          on: {
            "select_product": _vm.select_product
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          on: {
            "change": _vm.setSelected
          },
          model: {
            value: _vm.isAllSelected,
            callback: function ($$v) {
              _vm.isAllSelected = $$v;
            },
            expression: "isAllSelected"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('b-checkbox', {
          attrs: {
            "checked": _vm.selected.some(function (el) {
              return el.product.id === item.product.id;
            })
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            },
            "change": function (val) {
              return _vm.addProduct(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_order",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.int_order) + " ")])];
      }
    }, {
      key: "body_product",
      fn: function (_ref4) {
        var _item$product, _item$product2, _item$product$supplie, _item$product3, _item$product3$suppli;

        var item = _ref4.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column",
            "text-align": "left"
          }
        }, [_c('span', {
          staticStyle: {
            "text-overflow": "ellipsis",
            "overflow": "hidden"
          }
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.name))]), _c('div', {
          staticClass: "product-info-div"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : _item$product2.articul))]), _c('span', {
          staticClass: "supplier"
        }, [_vm._v(" " + _vm._s((_item$product$supplie = (_item$product3 = item.product) === null || _item$product3 === void 0 ? void 0 : (_item$product3$suppli = _item$product3.supplier) === null || _item$product3$suppli === void 0 ? void 0 : _item$product3$suppli.name) !== null && _item$product$supplie !== void 0 ? _item$product$supplie : '-') + " ")])])])];
      }
    }, {
      key: "body_measurement",
      fn: function (_ref5) {
        var _item$product$measure, _item$product4, _item$product4$measur;

        var item = _ref5.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s((_item$product$measure = (_item$product4 = item.product) === null || _item$product4 === void 0 ? void 0 : (_item$product4$measur = _item$product4.measurement) === null || _item$product4$measur === void 0 ? void 0 : _item$product4$measur.name) !== null && _item$product$measure !== void 0 ? _item$product$measure : '') + " ")])];
      }
    }, {
      key: "body_delta",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_c('table-row-input', {
          ref: 'delta' + item.id,
          attrs: {
            "fixed": _vm.order.fixed,
            "value": item.delta
          },
          on: {
            "handle-input": function (val) {
              return _vm.setDelta(val, item);
            },
            "input": function (val) {
              return _vm.setDelta(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_step_delta",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_c('table-row-input', {
          ref: 'step_delta' + item.id,
          attrs: {
            "fixed": _vm.order.fixed,
            "def_val": "-",
            "value": item.step_delta
          },
          on: {
            "handle-input": function (val) {
              return _vm.setStepDelta(val, item);
            },
            "input": function (val) {
              return _vm.setStepDelta(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_price",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_c('table-row-input', {
          ref: 'price' + item.id,
          attrs: {
            "fixed": _vm.order.fixed,
            "def_val": "-",
            "value": item.price
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPrice(val, item);
            },
            "input": function (val) {
              return _vm.setPrice(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_sum",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.sum ? _vm.formatPrice(item.sum) : '-') + " ")])];
      }
    }, {
      key: "body_supply_date",
      fn: function (_ref10) {
        var value = _ref10.value;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(value ? _vm.convertDate(value) : '-') + " ")])];
      }
    }, {
      key: "body_remainder",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatDelta(_vm.getTotalRemainders(item))) + " ")])];
      }
    }, {
      key: "body_sold",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatDelta(item.sold)) + " ")])];
      }
    }, {
      key: "body_sold_rate",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.sold_rate)) + " ")])];
      }
    }, {
      key: "body_sold_period",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.sold_period)) + " ")])];
      }
    }, {
      key: "body_critical_level",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_c('table-row-input', {
          ref: 'critical_level' + item.id,
          attrs: {
            "fixed": _vm.order.fixed,
            "value": item.critical_level
          },
          on: {
            "handle-input": function (val) {
              return _vm.setCriticalLevel(val, item);
            },
            "input": function (val) {
              return _vm.setCriticalLevel(val, item);
            }
          }
        })], 1)];
      }
    }])
  }), _c('order-navbar', {
    attrs: {
      "items": _vm.selected,
      "all_selected": _vm.isAllSelected,
      "clear_selected": _vm.clearSelected,
      "count": _vm.order.specifications.total
    },
    on: {
      "remove_items": _vm.removeItems,
      "show_move": _vm.showMovement
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2"
  }, [_c('strong', [_vm._v("Пересчитываем заказ...")])]);

}]

export { render, staticRenderFns }