var render = function render(){
  var _vm$value$name, _vm$value, _vm$value2, _vm$value3, _vm$search, _this$filtered_list;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "custom-select"
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.hide,
      expression: "hide"
    }],
    staticClass: "root-cont",
    staticStyle: {
      "width": "205px"
    },
    style: _vm.disabled ? 'cursor: not-allowed; background:#F2F3F6' : ''
  }, [_c('span', {
    staticClass: "placeholder-span",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.show.apply(null, arguments);
      }
    }
  }, [!_vm.multiple ? _c('span', [!_vm.show_list || !_vm.searchable ? _c('span', {
    staticClass: "single-placeholder"
  }, [_vm._v(_vm._s((_vm$value$name = (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.name) !== null && _vm$value$name !== void 0 ? _vm$value$name : _vm.placeholder) + " ")]) : _vm._e(), _vm.show_list && _vm.searchable ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    ref: "search-select-input",
    staticStyle: {
      "border": "none !important",
      "height": "28px !important",
      "width": "170px"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }) : _vm._e()]) : _vm._e(), _vm.multiple ? _c('span', {
    staticStyle: {
      "overflow": "hidden"
    }
  }, [!_vm.show_list ? _c('div', {
    staticClass: "multi-placeholder"
  }, [((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.length) > 1 ? _c('span', {
    staticClass: "count-tag"
  }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.length))]) : _vm._e(), _c('span', {
    staticClass: "select-placeholder ml-2"
  }, [_vm._v(_vm._s(_vm.multi_tag))])]) : _vm._e(), _vm.show_list && _vm.searchable ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    ref: "search-select-input",
    staticStyle: {
      "border": "none !important",
      "height": "28px !important"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }) : _vm._e()]) : _vm._e(), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })]), _c('transition', {
    attrs: {
      "duration": "200"
    }
  }, [!_vm.group_select && _vm.show_list ? _c('div', {
    staticClass: "option-container"
  }, [_vm._l(_vm.filtered_list, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "option",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.select_item(item);
        }
      }
    }, [_vm.multiple ? _c('img', {
      staticClass: "m-0 mr-2",
      attrs: {
        "src": _vm.checkbox(item)
      }
    }) : _vm._e(), _c('span', {
      staticStyle: {
        "overflow": "hidden",
        "text-overflow": "ellipsis"
      }
    }, [_vm._t("option_container", function () {
      return [_vm._v(_vm._s(item[_vm.name_field]))];
    }, {
      "item": item,
      "value": item[_vm.name_field]
    })], 2)]);
  }), (_vm$search = _vm.search) !== null && _vm$search !== void 0 && _vm$search.length && !((_this$filtered_list = this.filtered_list) !== null && _this$filtered_list !== void 0 && _this$filtered_list.length) ? _c('div', {
    staticClass: "center p-2"
  }, [_vm._v(" Ничего не найдено! ")]) : _vm._e(), _vm._t("list-footer")], 2) : _vm._e()]), _vm.group_select ? _c('div', {
    staticClass: "option-container"
  }, [_c('div', {
    staticClass: "option"
  }, [_vm._v("123")])]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }