var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear
    }
  }), _vm._v(" " + _vm._s(_vm.all_selected ? _vm.count : _vm.items.length) + " " + _vm._s(_vm.item_name) + " "), _c('div', {
    staticClass: "b-btns",
    staticStyle: {
      "margin-top": "0 !important"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.removeItem
    }
  }, [_vm._v(" Убрать из заказа ")]), _vm.items.length === 1 ? [_c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.showMovement
    }
  }, [_vm._v(" Показать товародвижение ")])] : _vm._e()], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }